<template lang="pug">
  div
    v-data-table(
      :loading="budgetCodeIndexGetting"
      :items="budgetCodeIndexList"
      :items-per-page.sync="itemsPerPage"
      :page.sync="page"
      :headers="headers"
      :server-items-length="budgetCodeIndexGetCount"
    )
      template(v-slot:top)
        v-row
          v-col(cols="12" lg="6" md="6")
            v-text-field(
              label="search"
              append-icon="mdi-magnify"
              v-model="search"
            )
      template(v-slot:item="{ item }")
        tr
          td(:class="item.new ? 'success--text' : 'primary--text'") {{ item.budget_code }}
          td(:class="item.new ? 'success--text' : ''") {{ item.description }}
          td &nbsp;
</template>
<script>
import budgetCodeRepository from '@/repositories/budget-code.repository'
import VueRequestHandler from '@/libs/classes/VueRequestHandler.class'
import { requestVars } from '@/libs/api-helper.extra'

const [budgetCodeIndexVars, budgetCodeIndexVarNames] = requestVars({ identifier: 'budget-code-index', pagination: true })
const budgetCodeIndexHandler = new VueRequestHandler(null, budgetCodeIndexVarNames, {}, true)

const tableVars = () => ({
  headers: [
    { text: 'Budget Code', value: 'budget_code' },
    { text: 'Description', value: 'description' },
    { text: 'config', sortable: false },
  ],
  page: 1,
  itemsPerPage: 10,
  search: null,
})

export default {
  name: 'BudgetCodeTable',
  data () {
    return {
      ...budgetCodeIndexVars,
      ...tableVars(),
    }
  },
  computed: {
    budgetCodeParams () {
      return {
        search: this.search,
        limit: this.itemsPerPage,
        page: this.page,
        paginate: true,
      }
    },
  },
  created () {
    this.getBudgetCodes()
    this.initWebsocket()
  },
  watch: {
    search () {
      this.page = 1
    },
    budgetCodeParams () {
      this.getBudgetCodes()
    },
  },
  methods: {
    getBudgetCodes () {
      const handler = budgetCodeIndexHandler
      const repository = budgetCodeRepository.index
      const params = this.budgetCodeParams
      handler.setVue(this)
      handler.execute(repository, [params])
    },
    initWebsocket () {
      const { echo } = this.$store.state.websocket
      echo.private('database.event')
        .listen('DBStoreEvent', this.dbStoreEvent)
    },
    dbStoreEvent ({ model, data }) {
      if (!model && this.$objectEmpty(data)) return
      if (model === 'BudgetCode') {
        data.new = true
        this.budgetCodeIndexList.push(data)
      }
    },
  },
}
</script>